import { LayerListPosition, ParcelListType, type ModuleConfig } from '../models';
import { markRaw } from 'vue';
import {
  FertilizerPlanTypeIcon,
  HomogenousDosePlanIcon,
  PesticidePlanTypeIcon,
  ProPlantaAdviceDosePlanIcon,
  SeedPlanTypeIcon,
  SelfZoneDosePlanIcon,
  ZoneStrengthDosePlanIcon
} from '../modules/plan';
import { DosePlan, PlanType } from '../modules/plan/models/plan.model';
import { PlanFileType } from '../modules/plan/enums/plan-file-type.enum';

export const createDefaultModuleConfig = (): ModuleConfig => {
  return {
    parcel: {
      listType: ParcelListType.FODLER,
      needParcelGroups: true,
      needGeoLayerUpload: true,
      layerListPosition: LayerListPosition.BOTTOM,
      needParcelLayerEditButton: true,
      needParcelSubLayerButton: true
    },
    map: {
      addedLayersNavLocation: 'right',
      layersNavLocation: 'left',
      mapSourceURL: '/here-api/v3/base/mc/{z}/{x}/{y}/png8',
      attributions: `${new Date().getFullYear()} &copy; <a href="https://developer.here.com" target="_blank">HERE Technologies</a>`,
      needAutoMapChange: true,
      numberOfMaps: 4,
      needSettings: true
    },
    fertilizer: {
      needFilters: true,
      needAddNew: true,
      needHeader: true
    },
    plan: {
      needExpertAdvice: true,
      needFileUpload: true,
      needSidenav: true,
      needZoneDetails: true,
      needDetailsZoom: true,
      needDetailsLayerChooser: true,
      fertilizersRouteName: 'fertilizersTab',
      planChooserType: 'card',
      planTypeOptions: [
        {
          planType: PlanType.FERTILIZER_PLAN,
          title: 'plan.planType.fertilizerPlan',
          icon: markRaw(FertilizerPlanTypeIcon)
        },
        {
          planType: PlanType.SEED_PLAN,
          title: 'plan.planType.seedPlan',
          icon: markRaw(SeedPlanTypeIcon)
        },
        {
          planType: PlanType.PESTICIDE_PLAN,
          title: 'plan.planType.pesticidePlan',
          icon: markRaw(PesticidePlanTypeIcon)
        }
      ],
      doseTypeOptions: [
        {
          doseType: DosePlan.HOMOGENEOUS,
          title: 'plan.dosePlan.homogeneous',
          icon: markRaw(HomogenousDosePlanIcon),
          tooltip: `plan.wizard.doseTypeTooltip.${DosePlan.HOMOGENEOUS}`
        },
        {
          doseType: DosePlan.SELF_ZONE,
          title: 'plan.dosePlan.selfZone',
          icon: markRaw(SelfZoneDosePlanIcon),
          tooltip: `plan.wizard.doseTypeTooltip.${DosePlan.SELF_ZONE}`
        },
        {
          doseType: DosePlan.ZONE_STRENGTH,
          title: 'plan.dosePlan.zoneStrength',
          icon: markRaw(ZoneStrengthDosePlanIcon),
          tooltip: `plan.wizard.doseTypeTooltip.${DosePlan.ZONE_STRENGTH}`
        },
        {
          doseType: DosePlan.ADVICE,
          title: 'plan.dosePlan.advice',
          icon: markRaw(ProPlantaAdviceDosePlanIcon),
          enabled: (planType?: PlanType) => planType === PlanType.FERTILIZER_PLAN,
          tooltip: `plan.wizard.doseTypeTooltip.${DosePlan.ADVICE}`
        }
      ],
      availableDownloadFormats: Object.values(PlanFileType)
    }
  };
};
